.login-box {
  text-align: center;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-title {
  text-align: center;
  padding-top: 40px;
  margin-bottom: 40px;
  font-family: Prata, serif;
  color: white;
  font-size: 45px;
}

.login-box input {
  display: block;
  width: 200px;
  padding-bottom: 10px;
}

.login-coming-soon {
  padding-top: 30px;
  font-size: 45px;
  font-style: italic;
}
