.header-wrap {
  height: 50px;
  width: 100%;
  color: white;
  background-color: #1d3354;
  position: fixed;
  top: 0;
  z-index: 4;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.header-ends {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-menu-links {
  display: inline-block;
}

.header-menu-icon {
  display: none;
}

.header-site-name {
  font-weight: bold;
  padding-right: 40px;
  font-style: italic;
  color: white;
  text-decoration: none;
  padding-left: 20px;
}

.header-link {
  color: white;
  text-decoration: none;
  padding-right: 20px;
}

.mobile-menu {
  position: absolute;
  background-color: #1d3354;
  top: 50px;
  right: 0;
  display: flex;
  flex-direction: column;
  min-width: 200px;

  .header-link {
    padding: 20px;
  }

  .mobile-trial-link {
    display: none;
  }
}

.mobile-menu-close {
  text-align: right;
}

.mobile-menu-close--icon {
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header-menu-links {
    display: none;
  }

  .header-menu-icon {
    display: block;
    font-size: 24px;
    cursor: pointer;
    color: white;
    padding: 13px 20px 12px 20px;
  }

  .free-trial-link {
    padding-right: 0;
  }
}

@media screen and (max-width: 400px) {
  .free-trial-link {
    display: none;
  }

  .mobile-menu .mobile-trial-link {
    display: block;
  }
}
