.main-banner-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 400px;
  width: 100%;
  z-index: 0;
  margin-top: 50px; //add header variable
  // margin-bottom: 40px;
}

.home-image {
  position: absolute;
  height: 400px;
  object-fit: cover;
  object-position: 0 65%;
  width: 100%;
}

.main-banner-text {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.home-header-text {
  font-family: Prata, serif;
  color: white;
  font-size: 45px;
  width: 350px;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1;
  font-weight: normal;
}

.home-image-sub-text {
  font-family: Source Sans Pro, sans-serif;
  color: white;
  font-size: 25px;
  margin-bottom: 40px;
  text-align: center;
  width: 450px;
}

.main-button {
  text-align: center;
  color: #1d3354;
  background-color: #9ed8d9;
  border-color: #9ed8d9;
  border-radius: 20px;
  padding: 12px 20px 10px;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 700;
  line-height: 1;
  margin: 5px 20px 0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
}

.home-image-sub-link {
  font-weight: bold;
  color: #9ed8d9;
  margin-top: 20px;
}

.popular-story-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: white;
}

.home-section {
  background-color: #9ed8db;
  min-height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.info-block {
  width: 250px;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 50px; // move to last section because of footer?
}

.info-block--title {
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  padding-bottom: 10px;
}

.info-block--text {
  padding: 0 20px;
}

.info-block--icon {
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 70px;
}

@media screen and (max-width: 768px) {
  .home-image-sub-text {
    width: 300px;
  }

  .home-section {
    justify-content: center;
  }
}
