.library-title {
  text-align: center;
  padding-top: 40px;
  margin-bottom: 40px;
  font-family: Prata, serif;
  color: white;
  font-size: 45px;
  font-weight: 300;
}

.story-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px; //replace with footer height variable
  justify-content: space-evenly;
}

.library-page {
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  background-color: white;
}

// .library-main {

// }

.library-filters {
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.library-input-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 20px 10px;
}

.library-search-icon {
  color: black;
}

.book-search-input {
  margin-left: 10px;
  font-size: 20px;
  max-width: 300px;
}
