.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
}

.contact-page--subtitle {
  font-family: Source Sans Pro, sans-serif;
  font-size: 25px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 100;
  width: 700px;
}

.contact-page--blocks {
  display: flex;
  flex-direction: row;
  width: 90%;
  flex: 1;
  margin-bottom: 100px;
  min-height: 900px;
}

.contact--email-block {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 40px;
  font-size: 20px;
}

.contact--email-block-text {
  padding: 0 20px;
  text-align: center;
}

.contact-email {
  color: black;
}

@media screen and (max-width: 768px) {
  .contact-page--subtitle {
    width: 300px;
  }

  .contact-page--blocks {
    flex-direction: column;
    min-height: 1400px;
  }
}
