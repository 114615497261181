.footer-section {
  position: relative;
  display: flex;
  flex-direction: column;
}

.footer-wrap {
  height: 50px;
  width: 100%;
  color: white;
  background-color: #1d3354;
  position: fixed;
  bottom: 0;
  z-index: 4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer-wrap-unstick {
  position: relative;
}

.footer-link {
  color: white;
  text-decoration: none;
  padding-right: 20px;
}

.footer-secondary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px;
  flex: 1;
}

.footer-secondary--link {
  color: white;
  text-decoration: none;
  display: block;
  padding-bottom: 20px;
}

.footer-copyright {
  color: white;
  text-align: center;
  padding-bottom: 20px;
}

.footer-icons {
  display: flex;
}

.footer-insta-icon {
  font-size: 50px;
}

.footer-insta-icon.svg-inline--fa.fa-w-14 {
  width: 64px;
  height: 64px;
}

.footer-insta-icon {
  color: white;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
