.story-page {
  background-color: white;
  color: black;
  font-family: Source Sans Pro, sans-serif;
  min-height: 800px;
}

.story-title {
  text-align: center;
  font-size: 30px;
  padding: 40px 0 10px;
  font-weight: 400;
  margin: 0;
}

.story-top {
  background-color: black;
  color: white;
  text-align: center;
  padding-bottom: 20px;
}

.story-product-info {
  width: 600px;
  padding-top: 20px;
  margin: auto;
}

.trial-product-page {
  background-color: white;
  color: black;
  min-height: 500px;

  .trial-title {
    margin-top: 10px;
  }

  .coming-soon-text {
    color: black;
    top: 130px;
  }
}

.share-review-block {
  padding-bottom: 150px;
  text-align: center;
}

.share-text {
  font-size: 16px;
  padding: 0 20px 20px;
}

.share-review-sections {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .story-product-info {
    width: 250px;
  }
}
