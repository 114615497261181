.trial-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 800px;
  position: relative;
}

.trial-title {
  font-family: Prata, serif;
  font-size: 30px;
  margin-top: 60px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1;
  padding-top: 20px;
}

.trial-button {
  background-color: #ffa000;
  text-decoration: none;
  font-size: 16px;
  padding: 12px 20px;
  border-radius: 2px;
  margin-top: 20px;
  color: black;
}

.trial-subtext {
  padding: 10px 0 30px;
  font-size: 12px;
}

.coming-soon-text {
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  color: white;
  font-weight: bold;
  font-size: 30px;
  position: absolute;
  font-style: italic;
  top: 160px;
}

.trial-checklist {
  padding-top: 40px;
  padding-bottom: 400px;
}

.trial-check-item {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}

.trial-check-icon {
  color: #ffa000;
  font-size: 30px;
  padding-right: 12px;
}

@media screen and (max-width: 768px) {
  .about-page p {
    width: 250px;
  }

  .about-page--button {
    width: 250px;
    text-align: center;
    padding: 12 12px 16px;
  }
}
