.main-content {
  width: 100%;
  margin-top: 50px;
  color: white;
}

.page-title {
  font-family: Prata, serif;
  font-size: 45px;
  margin-top: 60px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1;
  padding-top: 20px;
  font-weight: 300;
}

.text-link {
  color: #cd2653;
}
