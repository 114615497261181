.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 800px;
}

.about-page p {
  width: 600px;
  text-align: center;
  line-height: 1.5;
  font-size: 18px;
}

.about-page--button {
  background-color: #cd2653;
  color: white;
  text-decoration: none;
  font-size: 24px;
  padding: 12px 26px 16px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .about-page p {
    width: 250px;
  }

  .about-page--button {
    width: 250px;
    text-align: center;
    padding: 12 12px 16px;
  }
}
