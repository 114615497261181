.subscribe-form {
  text-align: center;
  padding: 100px 0;
  background-color: white;
}

.subscribe-form label {
  line-height: 150%;
  font-family: Helvetica;
  font-size: 16px;
}

.subscribe-form--title {
  font-size: 28px;
  padding-bottom: 10px;
}

.subscribe-email {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  background: #fff;
  width: 300px;
  border: 2px solid #d0d0d0 !important;
  border-radius: 3px;
  font-size: 16px;
}

.subscribe-button {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 42px;
  font-family: "Helvetica", Arial, sans-serif;
  width: auto;
  white-space: nowrap;
  height: 42px;
  padding: 0 22px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  vertical-align: top;
  background-color: #5d5d5d !important;
  color: #ffffff !important;
}
