.story-card {
  margin: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: black;
  text-decoration: none;
  flex: 1;
  align-items: center;
  text-align: center;
}

.story-book-cover {
  height: 250px;
  width: 250px;
  flex-direction: column;
  text-align: center;
  color: black;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.story-book-cover img {
  flex-shrink: 1;
  min-width: 100%;
  min-height: 100%;
}

.card-title-section {
  padding-bottom: 8px;
}

.book-title {
  font-weight: bold;
  padding: 10px 0;
  font-size: 22px;
  padding-bottom: 8px;
}

.book-descriptive-title {
  font-size: 16px;
  padding-bottom: 8px;
  font-style: italic;
  display: inline-block;
  line-height: 22px;
}

.book-descriptive-subtitle {
  font-size: 14px;
  padding-bottom: 8px;
}

.book-price {
  font-weight: bold;
  color: #cd2653;
}

.locked-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  color: black;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: right;
}

.lock-icon {
  font-size: 40px;
  color: black;
  padding-top: 10px;
  padding-right: 10px;
}

.new-tag {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  background: #db504a;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
}
