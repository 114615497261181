.step-completed-bar {
  height: 20px;
  border-bottom: 1px solid gray;
}

.step-completed-bar.step1 .step-completed--inner-bar,
.step-completed-bar.step2 .step-completed--inner-bar {
  height: 20px;
  width: 50%;
  background: #cd2653;
  display: block;
}

.step-completed-label {
  text-align: center;
  margin-bottom: 20px;
  color: #cd2653;
  font-size: 16px;
  font-style: italic;
}

.story-inputs {
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  margin-bottom: 60px;
  max-width: 600px;
}

.story-name-wrap {
  margin-bottom: 40px;
  display: block;
  font-weight: 400;
}

.story-name-wrap input {
  background: #fff;
  border-radius: 0;
  border-style: solid;
  border-width: 0.1rem;
  box-shadow: none;
  display: block;
  margin: 0;
  max-width: 100%;
  padding: 10px;
  font-size: 18px;
  width: 100%;
}

.sparrow-radio-label {
  display: block;
  font-weight: 400;
  padding: 10px;
}

.go-btn-wrapper {
  text-align: center;
  margin-bottom: 40px;
}

.story-go-btn {
  margin-top: 20px;
  background: #cd2653;
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.0333em;
  line-height: 1.25;
  margin: 0;
  opacity: 1;
  padding: 1.1em 1.44em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.15s linear;
}

.story-go-btn:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.story-author {
  padding-bottom: 30px;
  font-size: 16px;
  font-style: italic;
  text-align: center;
}

.background-text {
  font-style: italic;
  padding-bottom: 42px;
  text-align: center;
  font-size: 16px;
  padding-top: 12px;
  width: 70%;
  margin: 0 auto;
}

.main-story-wrap {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  min-height: 300px;
  font-size: 18px;
  line-height: 26px;
}

.the-end-text {
  margin: 0;
  text-align: center;
  padding: 16px 0 100px;
  font-size: 28px;
}

.to-be-continued {
  font-size: 24px;
  padding: 50px 10px;
  font-style: italic;
  text-align: center;
  display: block;
  color: #cd2653;
  text-decoration: none;
  margin: 0;
  padding-bottom: 50px;
}

.mySwiper {
  width: 300px;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: white;
}

@media screen and (max-width: 768px) {
  .story-name-wrap input {
    width: 250px;
  }
}

@media screen and (max-width: 400px) {
  .story-name-wrap input {
    width: 250px;
  }
}
